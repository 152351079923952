import React from 'react';
import { Link } from "react-router-dom";

import ServiceData from '../../data/services/servicesData';

const ServiceItems = (item) => {
    return (
        <div className="service-item">
            <div className="img object-fit">
                <div className="object-fit-cover">
                    <img src={process.env.REACT_APP_IMAGE_PATH + item.imgSrc} alt={item.title} />
                </div>
            </div>

            <div className="img-bg-color"></div>

            <h5 className="service-item-title">{item.title}</h5>
        </div>
    )
}

const AboutServices = () => {
    return (
        <div className="row gutter-width-md with-pb-sm">
            {ServiceData && ServiceData.map((item, key) => {
                return (
                    <div key={key} className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        {item.external ? <Link title={item.title} to={item.external} target="_blank" rel="noopener noreferrer">
                            {ServiceItems(item)}
                        </Link> : <Link title={item.title} to={item.external ?? '/service/' + item.link}>
                            {ServiceItems(item)}
                        </Link>}
                    </div>
                );
            })}
        </div>
    );
};

export default AboutServices;